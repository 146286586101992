import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditbarComponent } from '../../editbar';
import { UserService, IUser } from './user.service';
import { Router } from '@angular/router';
import { RapidStatsService } from '../../rapid-stats/rapid-stats.service';


@Component({
  selector: 'xeli-user',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})

export class UserEditComponent implements OnInit, OnDestroy {
  private sub: any;
  private modelSubscription: any;

  arrPiwikSite: any = [];

  modelData: IUser = {};





  constructor(private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private rapidStatsService: RapidStatsService) {

  }



  save(event) {
    this.userService.dataStore.data = this.modelData;
    this.userService.save();
  }

  remove(event) {
    this.userService.remove().subscribe(() => {
      this.router.navigate(['/admin', 'user']);
    });
  }

  overview(event) {
    this.router.navigate(['/admin', 'user']);
  }


  ngOnInit() {
    this.modelSubscription = this.userService.data$.subscribe((data) => {
      delete data.password;
      this.modelData = data;
    });
    this.sub = this.route.params.subscribe(params => {
      this.userService.loadUser(params['id']);
    });

    this.rapidStatsService.getPiwikSite().subscribe((sites: Array<Object>) => {
      this.arrPiwikSite = sites.map(function (obj: any) {
        return { id: obj.id, name: obj.name };
      });
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.modelSubscription.unsubscribe();
  }

}
