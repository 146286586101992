import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../common';
import { RapidStatsService } from '../rapid-stats.service';
import { GedenkportalService } from './gedenkportal.service';

import * as _ from 'lodash';



@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-rapid-stats-gedenkportal',
  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./gedenkportal.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './gedenkportal.component.html'

})
export class GedenkportalComponent implements OnInit, OnDestroy {
  public showData = false;
  public gedenkportal1 = [];
  public gedenkportal2 = [];
  public gedenkportalMorticians1 = [];
  public gedenkportalMorticians2 = [];
  private gedenkportalSubscription1;
  private gedenkportalSubscription2;
  private gedenkportalMorticiansSubscription1;
  private gedenkportalMorticiansSubscription2;
  public selectedName1 = '';
  public selectedName2 = '';

  public curSortKey = 'totalPages';
  public curSortOrder = 'asc';

  constructor(private rapidStatsService: RapidStatsService,
    private gedenkportalService: GedenkportalService,
    private authService: AuthService) {

  }

  sortBy(sortKey) {
    if (sortKey === this.curSortKey) {
      // Wenn man auf die aktuelle Sortierung klickt wird die Reihenfolge geändert.
      this.curSortOrder = (this.curSortOrder === 'asc') ? 'desc' : 'asc';
    }
    this.curSortKey = sortKey;

    if (this.curSortOrder === 'desc') {
      this.gedenkportal1 = _.sortBy(this.gedenkportal1, this.curSortKey).reverse();
    } else {
      this.gedenkportal1 = _.sortBy(this.gedenkportal1, this.curSortKey);
    }
  }

  showSortIcon(sortKey) {
    if (sortKey === this.curSortKey) {
      return true;
    }
  }

  showSelected2() {
    if (this.selectedName2 && this.selectedName2.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  setRowClasses(data) {
    const classes = {
      'table-success': (data.addressesCount > 9) ? true : false
    };
    return classes;
  }

  ngOnInit() {
    this.gedenkportalService.changeSubscribe();

    this.gedenkportalSubscription1 = this.gedenkportalService.gedenkportal1$.subscribe((res) => {
      this.showData = false;
      this.selectedName1 = this.gedenkportalService.selectedName1;
      this.gedenkportal1 = res;

      if (this.selectedName1 && this.selectedName1.length) {
        this.showData = true;
      }
    });

    this.gedenkportalSubscription2 = this.gedenkportalService.gedenkportal2$.subscribe((res) => {
      this.selectedName2 = this.gedenkportalService.selectedName2;
      this.gedenkportal2 = res;
    });

    this.gedenkportalMorticiansSubscription1 = this.gedenkportalService.gedenkportalMorticians1$.subscribe((res) => {
      this.gedenkportalMorticians1 = res;
    });

    this.gedenkportalMorticiansSubscription2 = this.gedenkportalService.gedenkportalMorticians2$.subscribe((res) => {
      this.gedenkportalMorticians2 = res;
    });

    this.gedenkportalService.loadGedenkportal();
    this.gedenkportalService.loadGedenkportalMortician();
  }

  ngOnDestroy() {
    this.gedenkportalSubscription1.unsubscribe();
    this.gedenkportalSubscription2.unsubscribe();
    this.gedenkportalMorticiansSubscription1.unsubscribe();
    this.gedenkportalMorticiansSubscription2.unsubscribe();
    this.gedenkportalService.changeUnsubscribe();
  }
}
