import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RapidStatsService } from '../rapid-stats.service';

export interface StatsData {
  deviceType?: string;
  deviceBrand?: string;
  deviceModel?: string;
  resolution?: string;
  total?: number;
}

@Injectable()
export class DevicesService {

  private _deviceTypes1$: Subject<StatsData[]>;
  private _deviceModels1$: Subject<StatsData[]>;
  private _deviceBrands1$: Subject<StatsData[]>;
  private _resolutions1$: Subject<StatsData[]>;

  private _deviceTypes2$: Subject<StatsData[]>;
  private _deviceModels2$: Subject<StatsData[]>;
  private _deviceBrands2$: Subject<StatsData[]>;
  private _resolutions2$: Subject<StatsData[]>;

  private _changeSubscription;
  private isLoading = true;

  private dataStore: {
    selectedName1: '',
    selectedName2: '',
    deviceTypes1: StatsData[],
    deviceModels1: StatsData[],
    deviceBrands1: StatsData[],
    resolutions1: StatsData[],
    deviceTypes2: StatsData[],
    deviceModels2: StatsData[],
    deviceBrands2: StatsData[],
    resolutions2: StatsData[]
  };


  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      selectedName1: '',
      selectedName2: '',
      deviceTypes1: [],
      deviceModels1: [],
      deviceBrands1: [],
      resolutions1: [],
      deviceTypes2: [],
      deviceModels2: [],
      deviceBrands2: [],
      resolutions2: []
    };
    this._deviceTypes1$ = <Subject<StatsData[]>>new Subject();
    this._deviceModels1$ = <Subject<StatsData[]>>new Subject();
    this._deviceBrands1$ = <Subject<StatsData[]>>new Subject();
    this._resolutions1$ = <Subject<StatsData[]>>new Subject();

    this._deviceTypes2$ = <Subject<StatsData[]>>new Subject();
    this._deviceModels2$ = <Subject<StatsData[]>>new Subject();
    this._deviceBrands2$ = <Subject<StatsData[]>>new Subject();
    this._resolutions2$ = <Subject<StatsData[]>>new Subject();

  }

  changeSubscribe() {
    this._changeSubscription = this.statsService.changed$.subscribe((val) => {
      this.isLoading = true;
      this.loadDeviceTypes();
      this.loadDeviceModels();
      this.loadDeviceBrands();
      this.loadResolutions();
    });
  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get selectedName1() {
    return this.dataStore.selectedName1;
  }

  get selectedName2() {
    return this.dataStore.selectedName2;
  }

  get deviceTypes1$() {
    return this._deviceTypes1$.asObservable();
  }

  get deviceTypes2$() {
    return this._deviceTypes2$.asObservable();
  }

  get deviceModels1$() {
    return this._deviceModels1$.asObservable();
  }

  get deviceModels2$() {
    return this._deviceModels2$.asObservable();
  }

  get deviceBrands1$() {
    return this._deviceBrands1$.asObservable();
  }

  get deviceBrands2$() {
    return this._deviceBrands2$.asObservable();
  }

  get resolutions1$() {
    return this._resolutions1$.asObservable();
  }

  get resolutions2$() {
    return this._resolutions2$.asObservable();
  }

  loadDeviceTypes() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getDeviceTypes', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.deviceTypes1 = res;
              this.dataStore.selectedName1 = this.statsService.selected[i].text;
              this._deviceTypes1$.next(this.dataStore.deviceTypes1);
            } else {
              this.dataStore.deviceTypes2 = res;
              this.dataStore.selectedName2 = this.statsService.selected[i].text;
              this._deviceTypes2$.next(this.dataStore.deviceTypes2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load deviceTypes.'));

      } else {
        if (i === 0) {
          this.dataStore.deviceTypes1 = [];
          this.dataStore.selectedName1 = '';
          this._deviceTypes1$.next(this.dataStore.deviceTypes1);
        } else {
          this.dataStore.deviceTypes2 = [];
          this.dataStore.selectedName2 = '';
          this._deviceTypes2$.next(this.dataStore.deviceTypes2);
        }
      }
    }
  }

  loadDeviceModels() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getDeviceModels', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.deviceModels1 = res;
              this._deviceModels1$.next(this.dataStore.deviceModels1);
            } else {
              this.dataStore.deviceModels2 = res;
              this._deviceModels2$.next(this.dataStore.deviceModels2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load deviceModels.'));
      } else {
        if (i === 0) {
          this.dataStore.deviceModels1 = [];
          this._deviceModels1$.next(this.dataStore.deviceModels1);
        } else {
          this.dataStore.deviceModels2 = [];
          this._deviceModels2$.next(this.dataStore.deviceModels2);
        }
      }
    }
  }

  loadDeviceBrands() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getDeviceBrands', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.deviceBrands1 = res;
              this._deviceBrands1$.next(this.dataStore.deviceBrands1);
            } else {
              this.dataStore.deviceBrands2 = res;
              this._deviceBrands2$.next(this.dataStore.deviceBrands2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load deviceBrands.'));
      } else {
        if (i === 0) {
          this.dataStore.deviceBrands1 = [];
          this._deviceBrands1$.next(this.dataStore.deviceBrands1);
        } else {
          this.dataStore.deviceBrands2 = [];
          this._deviceBrands2$.next(this.dataStore.deviceBrands2);
        }
      }
    }
  }

  loadResolutions() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getResolutions', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.resolutions1 = res;
              this._resolutions1$.next(this.dataStore.resolutions1);
            } else {
              this.dataStore.resolutions2 = res;
              this._resolutions2$.next(this.dataStore.resolutions2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load resolutions.'));
      } else {
        if (i === 0) {
          this.dataStore.resolutions1 = [];
          this._resolutions1$.next(this.dataStore.resolutions1);
        } else {
          this.dataStore.resolutions2 = [];
          this._resolutions2$.next(this.dataStore.resolutions2);
        }
      }
    }
  }



}
