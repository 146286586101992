import { registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { routing, appRoutingProviders } from './app.routing';

import { NoContentComponent } from './no-content';
import { DeskModule } from './desk';
import { LoginComponent } from './login';
import { RapidStatsModule } from './rapid-stats';
import { AdminModule } from './admin';

import { AuthService, AuthGuard } from './common';
// import { ReferrerService } from './rapid-stats/referrer/referrer.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { NotificationsService } from 'angular2-notifications';

import { NgSelectModule } from '@ng-select/ng-select';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { LOCALE_ID } from '@angular/core';
import localeDe from '@angular/common/locales/de';

import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';

export function tokenGetter() {
  return localStorage.getItem('token');
}

registerLocaleData(localeDe);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DeskModule,
    AdminModule,
    NgSelectModule,
    RapidStatsModule,
    NgxDatatableModule,
    NgbModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    }),
    routing
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    NoContentComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    appRoutingProviders,
    NotificationsService,
    AuthService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
