import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  catchError,
  tap,
} from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { NotificationsService } from 'angular2-notifications';


export interface IMorticianGroup {
  id?: string;
  name?: string;
  members?: any;
}

@Injectable()
export class MorticianGroupService {

  private _list$: Subject<IMorticianGroup[]>;
  private _data$: Subject<IMorticianGroup>;


  public dataStore: {
    list: IMorticianGroup[],
    data: IMorticianGroup
  };


  constructor(private http: HttpClient, private notificationsService: NotificationsService) {
    this.dataStore = {
      list: [],
      data: {}
    };
    this._list$ = <Subject<IMorticianGroup[]>>new Subject();
    this._data$ = <Subject<IMorticianGroup>>new Subject();
  }

  get data$() {
    return this._data$.asObservable();
  }

  get list$() {
    return this._list$.asObservable();
  }

  loadGroups() {
    const req = this.http.get('/api/desk/mortician-groups')
      .subscribe((res: IMorticianGroup[]) => {
        this.dataStore.list = res;
        this._list$.next(this.dataStore.list);
      }, error => console.log('Could not load mortician groups.'));
    return req;
  }

  loadGroup(id: string) {
    if (id === '0') {
      return;
    }
    const req = this.http.get('/api/desk/mortician-groups/' + id).subscribe((res: IMorticianGroup) => {
      this.dataStore.data = res;
      this._data$.next(this.dataStore.data);
    }, error => console.log('Could not load mortician groups.'));
    return req;
  }

  save() {
    const reqBody = this.dataStore.data;
    let req;
    if (reqBody.id) {
      req = this.http.patch('/api/desk/mortician-groups/' + reqBody.id, reqBody);
    } else {
      req = this.http.post('/api/desk/mortician-groups', reqBody);
    }
    req.subscribe(
      (body: any) => {
          if (typeof body.id !== 'undefined') {
            this.dataStore.data.id = body.id;
          }
          this.notificationsService.success('Bestatter Gruppe gespeichert', '');
      }, error => console.log('Could not save.'));
  }

  remove() {
    if (typeof this.dataStore.data.id === 'undefined') {
      return;
    }
    const req = this.http.delete('/api/desk/mortician-groups/' + this.dataStore.data.id);
    req.subscribe((res: any) => {
      if (res.status === 200) {
        this.notificationsService.success('Bestatter Gruppe gelöscht', '');
      }
    }, error => console.log('Could not save.'));
    return req;
  }

}
