import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RapidStatsService } from '../rapid-stats.service';

export interface StatsData {
  url?: string;
  uniqueVisitors?: number;
  totalVisitors?: number;
  de_rangeStart?: string;
  de_rangeEnd?: string;
  avgGenerationTime?: number;
  avgTimeSpent?: number;
  entry?: number;
  exit?: number;
  pageType?: string;
  pageTitle?: string;
  bounce?: number;
  total?: number;
}


@Injectable()
export class EntryPagesService {

  private _entryPages1$: Subject<StatsData[]>;
  private _entryPages2$: Subject<StatsData[]>;

  private _changeSubscription;
  private isLoading = true;

  private dataStore: {
    selectedName1: '',
    entryPages1: StatsData[],
    selectedName2: '',
    entryPages2: StatsData[]
  };


  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      selectedName1: '',
      entryPages1: [],
      selectedName2: '',
      entryPages2: [],
    };
    this._entryPages1$ = <Subject<StatsData[]>>new Subject();
    this._entryPages2$ = <Subject<StatsData[]>>new Subject();

  }

  changeSubscribe() {
    this._changeSubscription = this.statsService.changed$.subscribe((val) => {
      this.isLoading = true;
      this.loadEntryPages();
    });
  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get selectedName1() {
    return this.dataStore.selectedName1;
  }

  get entryPages1$() {
    return this._entryPages1$.asObservable();
  }

  get selectedName2() {
    return this.dataStore.selectedName2;
  }

  get entryPages2$() {
    return this._entryPages2$.asObservable();
  }

  loadEntryPages() {

    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {


        const params: HttpParams = new HttpParams()
        .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
        .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
        .set('selectedId', this.statsService.selected[i].id)
        .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getEntryPages', { params: params })
          .subscribe((res: any) => {
          if (i === 0) {
            this.dataStore.entryPages1 = res;
            this.dataStore.selectedName1 = this.statsService.selected[i].text;
            this._entryPages1$.next(this.dataStore.entryPages1);
          } else {
            this.dataStore.entryPages2 = res;
            this.dataStore.selectedName2 = this.statsService.selected[i].text;
            this._entryPages2$.next(this.dataStore.entryPages2);
          }
          this.isLoading = false;
        }, error => console.log('Could not load entryPages.'));
      } else {
        if (i === 0) {
          this.dataStore.entryPages1 = [];
          this._entryPages1$.next(this.dataStore.entryPages1);
        } else {
          this.dataStore.entryPages2 = [];
          this._entryPages2$.next(this.dataStore.entryPages2);
        }
      }
    }
  }
}
