// services/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IUser } from '../admin/user/user.service';
import { Subject } from 'rxjs';
import {
  catchError,
  tap
} from 'rxjs/operators';

@Injectable()
export class AuthService {
  jwtHelper = new JwtHelperService();

  private _currentUser: IUser = { rapid: false };
  private _currentUser$: Subject<IUser>;

  constructor(private http: HttpClient, public router: Router) {
    this._currentUser$ = <Subject<IUser>>new Subject();
    this.loadCurrentUser();
  }

  login(username: string, password: string) {
    const body = { username: username, password: password };

    this.http.post<any>('/api/auth/login', body).pipe(
      tap((res) => {
        localStorage.setItem('token', res.access_token);
        this.loadCurrentUser();
        this.router.navigate(['/']);
      })

    ).subscribe();
  }

  loadCurrentUser() {
    if (this.isLoggedIn()) {
      const decoded = this.jwtHelper.decodeToken(localStorage.getItem('token'));
      if (decoded) {
        const req = this.http.get('/api/desk/users/' + decoded.userId)
          .subscribe((res) => {
            this._currentUser = res;
            this._currentUser$.next(this._currentUser);
          }, error => console.log('Could not load current user.'));
        return req;
      }
    }
  }

  get currentUser$() {
    return this._currentUser$.asObservable();
  }

  get currentUser() {
    return this._currentUser;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  isLoggedIn() {
    if (this.getToken()) {
      return !this.jwtHelper.isTokenExpired(this.getToken());
    }
    return false;
  }

  isRapidUser() {
    if (typeof this._currentUser.rapid !== 'undefined' && this._currentUser.rapid === true) {
      return true;
    }
    return false;
  }



  logout() {
    localStorage.removeItem('token');
    this._currentUser = { rapid: false };
    this._currentUser$.next(this._currentUser);
    this.router.navigate(['/login']);
  }
}
