import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VisitorComponent } from './visitor';
import { PageViewsComponent } from './page-views';
import { EntryPagesComponent } from './entry-pages';
import { ExitPagesComponent } from './exit-pages';
// import { ReferrerComponent } from './referrer';
import { DevicesComponent } from './devices';
import { GedenkportalComponent } from './gedenkportal';

import { GedenkportalInternalComponent } from './gedenkportal-internal';
import { RapidStatsComponent } from './rapid-stats.component';
import { AuthGuard } from '../common/auth.guard';
import { DeskComponent } from '../desk/desk.component';
import { ReferrerOverviewComponent } from './referrer/referrer-overview/referrer-overview.component';
import { ReferrerSearchEnginesComponent } from './referrer/referrer-search-engines/referrer-search-engines.component';
import { ReferrerWebsitesComponent } from './referrer/referrer-websites/referrer-websites.component';

const routes: Routes = [
  {
    path: 'stats',
    component: DeskComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: RapidStatsComponent,
        canActivate: [AuthGuard],

        children: [
          { path: '', component: VisitorComponent, canActivate: [AuthGuard] },
          { path: 'visitor', component: VisitorComponent, canActivate: [AuthGuard] },
          { path: 'page-views', component: PageViewsComponent, canActivate: [AuthGuard] },
          { path: 'entry-pages', component: EntryPagesComponent, canActivate: [AuthGuard] },
          { path: 'exit-pages', component: ExitPagesComponent, canActivate: [AuthGuard] },
          {
            path: 'referrer',
            children: [
              { path: '', component: ReferrerOverviewComponent },
              { path: 'overview', component: ReferrerOverviewComponent },
              { path: 'search-engines', component: ReferrerSearchEnginesComponent },
              { path: 'websites', component: ReferrerWebsitesComponent },
            ]
          },
          { path: 'devices', component: DevicesComponent, canActivate: [AuthGuard] },
          { path: 'gedenkportal', component: GedenkportalComponent, canActivate: [AuthGuard] },
          { path: 'gedenkportal-internal', component: GedenkportalInternalComponent, canActivate: [AuthGuard] }
        ]
      }
    ]
  }
];


export const routing: ModuleWithProviders = RouterModule.forChild(routes);
