import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RapidStatsService } from '../rapid-stats.service';


export interface StatsData {
  url?: string;
  uniqueVisitors?: number;
  totalVisitors?: number;
  de_rangeStart?: string;
  de_rangeEnd?: string;
  exit?: number;
  unique?: number;
  bounce?: number;
  entry?: number;
  err?: any;
  avgGenerationTime?: number;
  avgTimeSpent?: number;
  pageType?: string;
  total?: number;
}


@Injectable()
export class PageViewsService {

  private _pageViews1$: Subject<StatsData[]>;
  private _pageViews2$: Subject<StatsData[]>;

  private _changeSubscription;
  private isLoading = true;

  private dataStore: {
    selectedName1: '',
    pageViews1: StatsData[],
    selectedName2: '',
    pageViews2: StatsData[]
  };


  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      selectedName1: '',
      pageViews1: [],
      selectedName2: '',
      pageViews2: []
    };
    this._pageViews1$ = <Subject<StatsData[]>>new Subject();
    this._pageViews2$ = <Subject<StatsData[]>>new Subject();
  }

  changeSubscribe() {
    this._changeSubscription = this.statsService.changed$.subscribe((val) => {
      this.isLoading = true;
      this.loadPageViews();
    });
  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get selectedName1() {
    return this.dataStore.selectedName1;
  }

  get pageViews1$() {
    return this._pageViews1$.asObservable();
  }

  get selectedName2() {
    return this.dataStore.selectedName2;
  }

  get pageViews2$() {
    return this._pageViews2$.asObservable();
  }

  loadPageViews() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {

        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getPageViews', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.pageViews1 = res;
              this.dataStore.selectedName1 = this.statsService.selected[i].text;
              this._pageViews1$.next(this.dataStore.pageViews1);
            } else {
              this.dataStore.pageViews2 = res;
              this.dataStore.selectedName2 = this.statsService.selected[i].text;
              this._pageViews2$.next(this.dataStore.pageViews2);
            }

            this.isLoading = false;
          }, error => console.log('Could not load pageViews.'));
      } else {
        if (i === 0) {
          this.dataStore.pageViews1 = [];
          this._pageViews1$.next(this.dataStore.pageViews1);
        } else {
          this.dataStore.pageViews2 = [];
          this._pageViews2$.next(this.dataStore.pageViews2);
        }
      }
    }
  }

}
