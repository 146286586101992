declare var moment: any;

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RapidStatsService } from '../rapid-stats.service';


export interface StatsData {
  uniqueVisitors?: number;
  totalVisitors?: number;
  activity?: number;
  totalContributions?: number;
  totalPages?: number;
  de_rangeStart?: string;
  de_rangeEnd?: string;
}


@Injectable()
export class GedenkportalInternalService {

  private _gedenkportalInternal$: Subject<StatsData[]>;

  private _changeSubscription;
  private isLoading = true;

  private dataStore: {
    gedenkportalInternal: StatsData[]
  };


  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      gedenkportalInternal: []
    };
    this._gedenkportalInternal$ = <Subject<StatsData[]>>new Subject();
  }

  changeSubscribe() {
    this.isLoading = true;
    this._changeSubscription = this.statsService.changed$.subscribe((val) => {
      this.loadGedenkportalInternal();
    });
  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get gedenkportalInternal$() {
    return this._gedenkportalInternal$.asObservable();
  }

  loadGedenkportalInternal() {
    const rangeStart = moment().subtract(50, 'years');
    const rangeEnd = moment();

    const params: HttpParams = new HttpParams()
      .set('rangeStart', rangeStart.format('YYYY-MM-DD'))
      .set('rangeEnd', rangeEnd.format('YYYY-MM-DD'));

    const req = this.http.get('/api/stats/getGedenkportalInternal', { params: params })
      .subscribe((res: any) => {
        this.dataStore.gedenkportalInternal = res;
        this._gedenkportalInternal$.next(this.dataStore.gedenkportalInternal);
        this.isLoading = false;
      }, error => console.log('Could not load gedenkportal internal.'));
  }

}
