import { Component, OnInit } from '@angular/core';
import { AuthService } from '../common';

@Component({
  templateUrl: './desk.component.html',
  styleUrls: ['./desk.component.scss']
})
export class DeskComponent implements OnInit {
  notificationsOptions = {
    timeOut : 3000
  };

  constructor(public authService: AuthService) {
  }

  public logout() {
    this.authService.logout();
  }

  ngOnInit() {
  }

}
