import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoContentComponent } from './no-content';
import { AuthGuard } from './common/auth.guard';
import { LoginComponent } from './login';



export const appRoutes: Routes = [
  { path: 'login',  component: LoginComponent },
  { path: '**',    component: NoContentComponent },
];


export const appRoutingProviders: any[] = [

];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
