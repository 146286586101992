import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { UserService, IUser } from './user.service';
import { ListbarComponent } from '../../listbar';
import { Router } from '@angular/router';


@Component({
  selector: 'xeli-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: [ './user-list.component.scss' ]
})

export class UserListComponent implements OnInit {
  public listData$;

  constructor(private router: Router, private userService: UserService) {
  }

  create(event) {
    this.router.navigate(['/admin', 'user', '0']);
  }

  ngOnInit() {
    this.listData$ = this.userService.list$;
    this.userService.loadUsers();
  }


}
