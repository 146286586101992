import { Component } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AuthService } from '../common/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(public auth: AuthService) {
  }

  login(event, username, password) {
    event.preventDefault();
    this.auth.login(username, password);
  }
}
