import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../../common';
import { ReferrerService } from '../referrer.service';
import { RapidStatsService } from '../../rapid-stats.service';

@Component({
  selector: 'app-referrer-websites',
  templateUrl: './referrer-websites.component.html',
  styleUrls: ['./referrer-websites.component.scss']
})
export class ReferrerWebsitesComponent implements OnInit, OnDestroy {

  public selectedName1 = '';
  public selectedName2 = '';
  public arrWebsites1 = [];
  public arrWebsites2 = [];

  private websitesSubscription1;
  private websitesSubscription2;


  constructor(private rapidStatsService: RapidStatsService, private referrerService: ReferrerService, private authService: AuthService) {
  }

  setRowClasses(website) {
    const classes =  {
      'bg-rapid-hellgrau': (website.referrerType === 'rapid') ? true : false
    };
    return classes;
  }

  showSelected1() {
    return (this.arrWebsites1.length > 0) ? true : false;
  }

  showSelected2() {
    return (this.arrWebsites2.length > 0) ? true : false;
  }

  ngOnInit() {
    this.referrerService.changeSubscribe('websites');

    this.websitesSubscription1 = this.referrerService.websites1$.subscribe((res) => {
      this.selectedName1 = this.referrerService.selectedName1;

      this.arrWebsites1 = res.map(val => {
        return {
          referrerName : val.referrerName,
          referrerType : val.referrerType,
          total : val.total
        };
      });
    });
    this.websitesSubscription2 = this.referrerService.websites2$.subscribe((res) => {
      this.selectedName2 = this.referrerService.selectedName2;
      this.arrWebsites2 = res.map(val => {
        return {
          referrerName : val.referrerName,
          referrerType : val.referrerType,
          total : val.total
        };
      });
    });
    this.referrerService.loadWebsites();
  }

  ngOnDestroy() {
    this.websitesSubscription1.unsubscribe();
    this.websitesSubscription2.unsubscribe();
    this.referrerService.changeUnsubscribe();
  }

}
