import { Component, OnInit } from '@angular/core';
import { PageScrollConfig } from 'ng2-page-scroll';
import { AuthService } from './common/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private auth: AuthService) {
    PageScrollConfig.defaultScrollOffset = 60;
    PageScrollConfig.defaultDuration = 0;
  }

  ngOnInit() {
  }
}
