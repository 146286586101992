import { Component } from '@angular/core';

@Component({
  selector: 'xeli-admin-sidebar',
  templateUrl: './admin-sidebar.component.html'
})

export class AdminSidebarComponent {
  isActive = false;
  showMenu = '';
  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
}
