import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RapidStatsService } from '../rapid-stats.service';


export interface StatsData {
  uniqueVisitors?: number;
  totalVisitors?: number;
  de_rangeStart?: string;
  de_rangeEnd?: string;
}

@Injectable()
export class GedenkportalService {

  private _gedenkportal1$: Subject<StatsData[]>;
  private _gedenkportal2$: Subject<StatsData[]>;
  private _gedenkportalMorticians1$: Subject<StatsData[]>;
  private _gedenkportalMorticians2$: Subject<StatsData[]>;

  private _changeSubscription;
  private isLoading = true;

  private dataStore: {
    selectedName1: '',
    selectedName2: '',
    gedenkportal1: StatsData[],
    gedenkportal2: StatsData[],
    gedenkportalMorticians1: StatsData[],
    gedenkportalMorticians2: StatsData[]
  };


  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      selectedName1: '',
      selectedName2: '',
      gedenkportal1: [],
      gedenkportal2: [],
      gedenkportalMorticians1: [],
      gedenkportalMorticians2: []
    };
    this._gedenkportal1$ = <Subject<StatsData[]>>new Subject();
    this._gedenkportal2$ = <Subject<StatsData[]>>new Subject();

    this._gedenkportalMorticians1$ = <Subject<StatsData[]>>new Subject();
    this._gedenkportalMorticians2$ = <Subject<StatsData[]>>new Subject();


  }

  changeSubscribe() {
    this._changeSubscription = this.statsService.changed$.subscribe((val) => {
      this.isLoading = true;
      this.loadGedenkportal();
      this.loadGedenkportalMortician();
    });
  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get selectedName1() {
    return this.dataStore.selectedName1;
  }

  get selectedName2() {
    return this.dataStore.selectedName2;
  }

  get gedenkportal1$() {
    return this._gedenkportal1$.asObservable();
  }

  get gedenkportal2$() {
    return this._gedenkportal2$.asObservable();
  }

  get gedenkportalMorticians1$() {
    return this._gedenkportalMorticians1$.asObservable();
  }

  get gedenkportalMorticians2$() {
    return this._gedenkportalMorticians2$.asObservable();
  }

  loadGedenkportal() {

    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {

        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getGedenkportal', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.selectedName1 = this.statsService.selected[i].text;
              this.dataStore.gedenkportal1 = res;
              this._gedenkportal1$.next(this.dataStore.gedenkportal1);
            } else {
              this.dataStore.selectedName2 = this.statsService.selected[i].text;
              this.dataStore.gedenkportal2 = res;
              this._gedenkportal2$.next(this.dataStore.gedenkportal2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load gedenkportal.'));
      } else {
        if (i === 0) {
          this.dataStore.gedenkportal1 = [];
          this._gedenkportal1$.next(this.dataStore.gedenkportal1);
        } else {
          this.dataStore.gedenkportal2 = [];
          this._gedenkportal2$.next(this.dataStore.gedenkportal2);
        }
      }
    }
  }

  loadGedenkportalMortician() {

    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {

        const params: HttpParams = new HttpParams()
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getGedenkportalMortician', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.gedenkportalMorticians1 = res;
              this._gedenkportalMorticians1$.next(this.dataStore.gedenkportalMorticians1);
            } else {
              this.dataStore.gedenkportalMorticians2 = res;
              this._gedenkportalMorticians2$.next(this.dataStore.gedenkportalMorticians2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load gedenkportal morticians.'));
      } else {
        if (i === 0) {
          this.dataStore.gedenkportalMorticians1 = [];
          this._gedenkportalMorticians1$.next(this.dataStore.gedenkportalMorticians1);
        } else {
          this.dataStore.gedenkportalMorticians2 = [];
          this._gedenkportalMorticians2$.next(this.dataStore.gedenkportalMorticians2);
        }
      }
    }
  }

}
