import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReferrerService } from '../referrer.service';
import { RapidStatsService } from '../../rapid-stats.service';
import { AuthService } from '../../../common';


@Component({
  selector: 'app-referrer-search-engines',
  templateUrl: './referrer-search-engines.component.html',
  styleUrls: ['./referrer-search-engines.component.scss']
})
export class ReferrerSearchEnginesComponent implements OnInit, OnDestroy {
  public selectedName1 = '';
  public selectedName2 = '';
  public arrSearchKeywords1 = [];
  public arrSearchNames1 = [];
  public arrSearchKeywords2 = [];
  public arrSearchNames2 = [];


  private searchNamesSubscription1;
  private searchKeywordsSubscription1;
  private searchNamesSubscription2;
  private searchKeywordsSubscription2;


  constructor(private rapidStatsService: RapidStatsService, private referrerService: ReferrerService, public authService: AuthService) {
  }


  showSelected1() {
    return (this.arrSearchNames1.length > 0) ? true : false;
  }

  showSelected2() {
    return (this.arrSearchNames2.length > 0) ? true : false;
  }

  ngOnInit() {
    this.referrerService.changeSubscribe('search');

    this.searchNamesSubscription1 = this.referrerService.searchNames1$.subscribe((res) => {
      this.selectedName1 = this.referrerService.selectedName1;

      this.arrSearchNames1 = res.map(val => {
        return {
          name: val.referrerName,
          total: val.total
        };
      });
    });
    this.searchNamesSubscription2 = this.referrerService.searchNames2$.subscribe((res) => {
      this.selectedName2 = this.referrerService.selectedName2;
      this.arrSearchNames2 = res.map(val => {
        return {
          name: val.referrerName,
          total: val.total
        };
      });
    });
    this.referrerService.loadSearchNames();


    this.searchKeywordsSubscription1 = this.referrerService.searchKeywords1$.subscribe((res) => {
      this.arrSearchKeywords1 = res.map(val => {
        return {
          name: val.referrerKeyword,
          total: val.total
        };
      });
    });
    this.searchKeywordsSubscription2 = this.referrerService.searchKeywords2$.subscribe((res) => {
      this.arrSearchKeywords2 = res.map(val => {
        return {
          name: val.referrerKeyword,
          total: val.total
        };
      });
    });
    this.referrerService.loadSearchKeywords();
  }

  ngOnDestroy() {
    this.searchNamesSubscription1.unsubscribe();
    this.searchNamesSubscription2.unsubscribe();
    this.searchKeywordsSubscription1.unsubscribe();
    this.searchKeywordsSubscription2.unsubscribe();
    this.referrerService.changeUnsubscribe();
  }

}
