import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  NotificationsService,
  SimpleNotificationsComponent,
} from 'angular2-notifications';

export interface IUser {
  id?: string;
  firm?: string;
  password?: string;
  rapid?: boolean;
  piwikSiteId?: number;
}

@Injectable()
export class UserService {
  private _list$: Subject<IUser[]>;
  private _data$: Subject<IUser>;

  public dataStore: {
    list: IUser[];
    data: IUser;
  };

  constructor(
    private http: HttpClient,
    private notificationsService: NotificationsService,
  ) {
    this.dataStore = {
      list: [],
      data: {},
    };
    this._list$ = <Subject<IUser[]>>new Subject();
    this._data$ = <Subject<IUser>>new Subject();
  }

  get data$() {
    return this._data$.asObservable();
  }

  get list$() {
    return this._list$.asObservable();
  }

  loadUsers() {
    const req = this.http.get('/api/desk/users').subscribe(
      (res: any) => {
        this.dataStore.list = res;
        this._list$.next(this.dataStore.list);
      },
      error => console.log('Could not load users.'),
    );
    return req;
  }

  loadUser(id: string) {
    if (id === '0') {
      return;
    }
    const req = this.http.get('/api/desk/users/' + id).subscribe(
      (res: any) => {
        this.dataStore.data = res;
        this._data$.next(this.dataStore.data);
      },
      error => console.log('Could not load user.'),
    );
    return req;
  }

  save() {
    let req;
    if (this.dataStore.data.id) {
      if (this.dataStore.data.password === '') {
        delete this.dataStore.data.password;
      }
      req = this.http.patch(
        '/api/desk/users/' + this.dataStore.data.id,
        this.dataStore.data,
      );
    } else {
      req = this.http.post('/api/desk/users', this.dataStore.data);
    }
    req.subscribe(
      (res: any) => {
        if (typeof res.id !== 'undefined') {
          this.dataStore.data.id = res.id;
        }
        this.notificationsService.success('User gespeichert', '');
      },
      error => console.log('Could not save.'),
    );
    return req;
  }

  remove() {
    if (typeof this.dataStore.data.id === 'undefined') {
      return;
    }
    const req = this.http.delete('/api/desk/users/' + this.dataStore.data.id);
    req.subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.notificationsService.success('User gelöscht', '');
        }
      },
      error => console.log('Could not save.'),
    );
    return req;
  }
}
