import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationsService, SimpleNotificationsComponent } from 'angular2-notifications';


export interface IReferrerUrl {
  id?: string;
  url?: string;
}

@Injectable()
export class ReferrerUrlService {

  private _list$: Subject<IReferrerUrl[]>;
  private _data$: Subject<IReferrerUrl>;


  public dataStore: {
    list: IReferrerUrl[],
    data: IReferrerUrl
  };


  constructor(private http: HttpClient, private notificationsService: NotificationsService) {
    this.dataStore = {
      list: [],
      data: {}
    };
    this._list$ = <Subject<IReferrerUrl[]>>new Subject();
    this._data$ = <Subject<IReferrerUrl>>new Subject();
  }

  get data$() {
    return this._data$.asObservable();
  }

  get list$() {
    return this._list$.asObservable();
  }

  loadReferrerUrls() {
    const req = this.http.get('/api/stats/referrer-urls')
      .subscribe((res: IReferrerUrl[]) => {
        this.dataStore.list = res;
        this._list$.next(this.dataStore.list);
      }, error => console.log('Could not load referrer urls.'));
    return req;
  }

  loadReferrerUrl(id: string) {
    if (id === '0') {
      return;
    }
    const req = this.http.get('/api/stats/referrer-urls/' + id).subscribe((res: any) => {
      this.dataStore.data = res;
      this._data$.next(this.dataStore.data);
    }, error => console.log('Could not load referrer url.'));
    return req;
  }

  save() {
    const reqBody = this.dataStore.data;
    let req;
    if (reqBody.id) {
      req = this.http.patch('/api/stats/referrer-urls/' + reqBody.id, reqBody);
    } else {
      req = this.http.post('/api/stats/referrer-urls', reqBody);
    }

    req.subscribe((body: any) => {
      if (typeof body.id !== 'undefined') {
        this.dataStore.data.id = body.id;
      }
      this.notificationsService.success('Referrer Url gespeichert', '');
    }, error => console.log('Could not save.'));
    return req;
  }

  remove() {
    if (typeof this.dataStore.data.id === 'undefined') {
      return;
    }
    const req = this.http.delete('/api/stats/referrer-urls/' + this.dataStore.data.id);
    req.subscribe((res: any) => {
      if (res.status === 200) {
        this.notificationsService.success('Referrer Url gelöscht', '');
      }
    }, error => console.log('Could not save.'));
    return req;
  }

}
