import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { EditbarComponent } from './editbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    EditbarComponent
  ],
  exports: [
    EditbarComponent
  ],
  providers: [
  ]
})
export class EditbarModule { }
