import { Component } from '@angular/core';
import { AuthService } from '../../common';

@Component({
  selector: 'xeli-stats-sidebar',
  styleUrls: ['./stats-sidebar.component.scss'],
  templateUrl: './stats-sidebar.component.html'
})

export class StatsSidebarComponent {
  isActive = false;
  showMenu = '';

  constructor(public authService: AuthService) {
  }

  eventCalled() {
    this.isActive = !this.isActive;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
}
