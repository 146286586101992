import { Component, EventEmitter, Output, Input } from '@angular/core';



@Component({
  selector: 'xeli-listbar',
  templateUrl: './listbar.component.html',
  styleUrls: ['./listbar.component.scss']
})

export class ListbarComponent {
  @Output() create = new EventEmitter<boolean>();

  @Input() heading: string;
  @Input() createLabel: string;

  constructor() {
  }

  onCreate() {
    this.create.emit(true);
  }
}
