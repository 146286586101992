import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { VisitorService } from './visitor.service';
import { Subscription } from 'rxjs/Subscription';
import { RapidStatsService } from '../rapid-stats.service';


@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-rapid-stats-visitor',

  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./visitor.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './visitor.component.html'
})


export class VisitorComponent implements OnInit, OnDestroy {
  public visits1: any = {};
  public visits2: any = {};
  @Input() name: string;

  public historyChartData: Array<any> = [];
  public historyChartLabels: Array<any> = [];

  public historyChartOptions: any = {
    animation: false,
    responsive: true
  };

  public historyChartColors: Array<any> = this.rapidStatsService.chartColors;
  public historyChartLegend = true;

  private visitsSubscription1;
  private visitsSubscription2;
  private historySubscription;

  constructor(private rapidStatsService: RapidStatsService, private visitorService: VisitorService) {

  }

  showSelected1() {
    if (typeof this.visits1.totalVisitors !== 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  showSelected2() {
    if (typeof this.visits2.totalVisitors !== 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.visitorService.changeSubscribe();
    this.visitsSubscription1 = this.visitorService.visits1$.subscribe((visits) => {
      this.visits1 = visits;
    });

    this.visitsSubscription2 = this.visitorService.visits2$.subscribe((visits) => {
      this.visits2 = visits;
    });

    this.historySubscription = this.visitorService.history$.subscribe((history) => {


      const totalVisitors1 = { data: [], label: 'Besuche' };
      const uniqueVisitors1 = { data: [], label: 'Eindeutige Besucher' };
      const totalVisitors2 = { data: [], label: 'Besuche 2' };
      const uniqueVisitors2 = { data: [], label: 'Eindeutige Besucher 2' };
      const historyChartLabels: Array<any> = [];
      if (typeof history[0] !== 'undefined' && history[0].length) {
        for (const val of history[0]) {
          uniqueVisitors1.data.push(val.uniqueVisitors);
          totalVisitors1.data.push(val.totalVisitors);
          historyChartLabels.push(val.de_rangeStart + ' - ' + val.de_rangeEnd);
        }
        if (typeof history[1] !== 'undefined' && history[1].length) {
          for (const val of history[1]) {
            uniqueVisitors2.data.push(val.uniqueVisitors);
            totalVisitors2.data.push(val.totalVisitors);
          }
          this.historyChartData = [totalVisitors1, uniqueVisitors1, totalVisitors2, uniqueVisitors2];
        } else {
          this.historyChartData = [totalVisitors1, uniqueVisitors1];
        }
        this.historyChartLabels = historyChartLabels;
      }

    });


    this.visitorService.loadVisits();
    this.visitorService.loadHistory();
  }




  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.historySubscription.unsubscribe();
    this.visitsSubscription1.unsubscribe();
    this.visitsSubscription2.unsubscribe();
    this.visitorService.changeUnsubscribe();
  }





}
