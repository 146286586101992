import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { DeskComponent } from './desk.component';
import { deskRouting } from './desk.routing';
import { ListbarModule } from '../listbar/listbar.module';
import { EditbarModule } from '../editbar/editbar.module';
import { SimpleNotificationsModule } from 'angular2-notifications';

// CoreUI Components
import { NAV_DROPDOWN_DIRECTIVES } from '../shared/nav-dropdown.directive';
import { SIDEBAR_TOGGLE_DIRECTIVES } from '../shared/sidebar.directive';
import { AsideToggleDirective } from '../shared/aside.directive';
import { BreadcrumbsComponent } from '../shared/breadcrumb.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ListbarModule,
    EditbarModule,
    SimpleNotificationsModule.forRoot(),
    NgbModule,
    deskRouting
  ],
  declarations: [
    DeskComponent,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    BreadcrumbsComponent
  ],
  exports: [
    ListbarModule,
    EditbarModule
  ],
  providers: [
  ]
})
export class DeskModule { }
