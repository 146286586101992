import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../common';
import { RapidStatsService } from '../rapid-stats.service';
import { PageViewsService } from './page-views.service';

@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-rapid-stats-pageviews',
  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./page-views.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './page-views.component.html'
})
export class PageViewsComponent implements OnInit, OnDestroy {
  public selectedName1 = '';
  public pageViews1 = [];
  public selectedName2 = '';
  public pageViews2 = [];

  public pageTypeChartData1: Array<any> = [];
  public pageTypeChartData2: Array<any> = [];
  public pageTypeChartLabels: Array<any> = ['Webseite', 'Gedenkportal'];



  public pageTypeChartOptions: any = {
    animation: false,
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const allData = data.datasets[tooltipItem.datasetIndex].data;
          const tooltipLabel = data.labels[tooltipItem.index];
          const tooltipData = allData[tooltipItem.index];
          let total = 0;
          for (const i in allData) {
            if (typeof allData[i] !== 'undefined') {
              total += allData[i];
            }
          }
          const tooltipPercentage = Math.round((tooltipData / total) * 100);
          return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
        }
      }
    }
  };

  public pageTypeChartColors: Array<any> = [{ backgroundColor: ['#e8f1f6', '#4ab2f8'] }];

  public pageTypeChartLegend = true;

  private pageViewsSubscription1;
  private pageViewsSubscription2;


  constructor(private rapidStatsService: RapidStatsService, private pageViewsService: PageViewsService, private authService: AuthService) {

  }

  setRowClasses(pageView) {
    const classes = {
      'bg-rapid-hellblau': (pageView.pageType === 'gemeinsamtrauern') ? true : false
    };
    return classes;
  }

  showSelected1() {
    if (this.pageViews1.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  showSelected2() {
    if (this.pageViews2.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.pageViewsService.changeSubscribe();
    this.pageViewsSubscription1 = this.pageViewsService.pageViews1$.subscribe((res) => {
      this.selectedName1 = this.pageViewsService.selectedName1;
      this.pageViews1 = res.map(val => {
        return {
          url: val.url,
          avgGenerationTime: Math.round(val.avgGenerationTime * 100) / 100,
          avgTimeSpent: Math.round(val.avgTimeSpent),
          pageType: val.pageType,
          total: val.total,
          unique: val.unique,
          exit: val.exit,
          exitRate: Math.round((100 / val.unique) * val.exit),
          bounce: val.bounce,
          bounceRate: Math.round((100 / val.unique) * val.bounce),
          entry: val.entry
        };
      });

      let websiteViews = 0;
      let gemeinsamTrauernViews = 0;
      for (const val of this.pageViews1) {
        if (val.pageType === 'gemeinsamtrauern') {
          gemeinsamTrauernViews += parseInt(val.total, 10);
        } else {
          websiteViews += parseInt(val.total, 10);
        }
      }
      this.pageTypeChartData1 = [websiteViews, gemeinsamTrauernViews];
    });

    this.pageViewsSubscription2 = this.pageViewsService.pageViews2$.subscribe((res) => {
      this.selectedName2 = this.pageViewsService.selectedName2;

      this.pageViews2 = res.map(val => {
        return {
          url: val.url,
          avgGenerationTime: Math.round(val.avgGenerationTime * 100) / 100,
          avgTimeSpent: Math.round(val.avgTimeSpent),
          pageType: val.pageType,
          total: val.total,
          unique: val.unique,
          exit: val.exit,
          exitRate: Math.round((100 / val.unique) * val.exit),
          bounce: val.bounce,
          bounceRate: Math.round((100 / val.unique) * val.bounce),
          entry: val.entry
        };
      });

      let websiteViews = 0;
      let gemeinsamTrauernViews = 0;
      for (const val of this.pageViews2) {
        if (val.pageType === 'gemeinsamtrauern') {
          gemeinsamTrauernViews += parseInt(val.total, 10);
        } else {
          websiteViews += parseInt(val.total, 10);
        }
      }

      this.pageTypeChartData2 = [websiteViews, gemeinsamTrauernViews];

    });
    this.pageViewsService.loadPageViews();
  }

  ngOnDestroy() {
    this.pageViewsSubscription1.unsubscribe();
    this.pageViewsSubscription2.unsubscribe();
    this.pageViewsService.changeUnsubscribe();
  }
}
