declare var $: any;
declare var moment: any;

import { Component, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditbarComponent } from '../../editbar';
import { UserService, IUser } from '../user/user.service';
import { Router } from '@angular/router';
import { MorticianGroupService, IMorticianGroup } from './mortician-group.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-mortician-group-edit',
  templateUrl: './mortician-group-edit.component.html',
  styleUrls: ['./mortician-group-edit.component.scss']
})

export class MorticianGroupEditComponent implements OnInit, OnDestroy {
  private sub: any;
  private modelSubscription: any;
  private userListSubscription: any;
  memberNames: any = [];

  modelData: IMorticianGroup = {};


  // public dataService: CompleterData;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private morticianGroupService: MorticianGroupService,
    private userService: UserService) {
    // this.dataService = this.completerService.local([], 'firm', 'firm');
  }


  private removeMember(member) {
    const i = _.indexOf(this.modelData.members, member);
    if (i > -1) {
      this.modelData.members.splice(i, 1);
    }
  }

  private addMember(value: any) {
    if (typeof this.modelData.members === 'undefined') {
      this.modelData.members = [];
    }
    this.modelData.members.push(value.id);
  }

  private getMemberName(member) {
    const i = _.findIndex(this.memberNames, { id: member });
    if (i > -1) {
      return this.memberNames[i].text;
    }
  }

  save(event) {
    this.morticianGroupService.dataStore.data = this.modelData;
    this.morticianGroupService.save();
  }

  remove(event) {
    this.morticianGroupService.remove().subscribe(() => {
      this.router.navigate(['/admin', 'mortician-groups']);
    });
  }

  overview(event) {
    this.router.navigate(['/admin', 'mortician-groups']);
  }


  ngOnInit() {
    this.modelSubscription = this.morticianGroupService.data$.subscribe((data) => {
      this.modelData = data;
    });
    this.sub = this.route.params.subscribe(params => {
      this.morticianGroupService.loadGroup(params['id']);
    });

    this.userListSubscription = this.userService.list$.subscribe((users) => {
      this.memberNames = users.map(function (obj: any) {
        if (typeof obj.firm !== 'undefined' && obj.firm.length > 0) {
          return { id: obj.id, text: obj.firm };
        } else {
          return { id: obj.id, text: obj.username };
        }
      });

      // this.dataService.data(users);
    });
    this.userService.loadUsers();

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.modelSubscription.unsubscribe();
    this.userListSubscription.unsubscribe();
  }

}
