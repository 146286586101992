import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../common/auth.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private http: HttpClient, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/login']);
      return false;
    }

    if ((state.url === '/stats/gedenkportal-internal' || state.url === '/admin') && !this.authService.isRapidUser()) {
      // Admin route ist nur für Rapid User erreichbar
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
