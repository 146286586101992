declare var moment: any;

import { Component, TemplateRef, OnInit, ViewChild } from '@angular/core';
import { RapidStatsService } from '../rapid-stats.service';
import { GedenkportalInternalService } from './gedenkportal-internal.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-gedenkportal-internal',
  templateUrl: './gedenkportal-internal.component.html',
  styleUrls: ['./gedenkportal-internal.component.scss']
})
export class GedenkportalInternalComponent implements OnInit {
  public gedenkportalInternal = [];
  private gedenkportalInternalSubscription;
  public curSortKey = 'totalPages';
  public curSortOrder = 'asc';

  constructor(private rapidStatsService: RapidStatsService, private gedenkportalInternalService: GedenkportalInternalService) {
  }



  sortBy(sortKey) {
    if (sortKey === this.curSortKey) {
      // Wenn man auf die aktuelle Sortierung klickt wird die Reihenfolge geändert.
      this.curSortOrder = (this.curSortOrder === 'asc') ? 'desc' : 'asc';
    }
    this.curSortKey = sortKey;

    if (this.curSortOrder === 'desc') {
      this.gedenkportalInternal = _.sortBy(this.gedenkportalInternal, this.curSortKey).reverse();
    } else {
      this.gedenkportalInternal = _.sortBy(this.gedenkportalInternal, this.curSortKey);
    }
  }

  showSortIcon(sortKey) {
    if (sortKey === this.curSortKey) {
      return true;
    }
  }

  setActivityClasses(activity) {
    const classes = {
      'bg-rapid-gruen': (activity >= 1.9) ? true : false
    };
    return classes;
  }

  setDateClasses(date1) {
    const date2 = moment().subtract(14, 'days');
    const classes = {
      'bg-rapid-orange': (moment(date1).isAfter(date2)) ? false : true
    };
    return classes;
  }

  ngOnInit() {
    this.gedenkportalInternalService.changeSubscribe();

    this.gedenkportalInternalSubscription = this.gedenkportalInternalService.gedenkportalInternal$.subscribe((res) => {
      this.gedenkportalInternal = res.map(function (obj) {
        obj.activity = obj.totalContributions / obj.totalPages;
        return obj;
      });

    });
    this.gedenkportalInternalService.loadGedenkportalInternal();
  }

}
