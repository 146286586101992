import { Component, OnInit } from '@angular/core';
import { ReferrerUrlService, IReferrerUrl } from '../referrer-url.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-referrer-edit',
  templateUrl: './referrer-edit.component.html',
  styleUrls: ['./referrer-edit.component.scss']
})
export class ReferrerEditComponent implements OnInit {
  private sub: any;
  modelData: IReferrerUrl = {};
  private modelSubscription: any;


  constructor(private router: Router, private route: ActivatedRoute, private referrerUrlService: ReferrerUrlService) {

  }

  save(event) {
    this.referrerUrlService.dataStore.data = this.modelData;
    this.referrerUrlService.save();
  }

  remove(event) {
    this.referrerUrlService.remove().subscribe(() => {
      this.router.navigate(['/admin', 'referrer']);
    });
  }

  overview(event) {
    this.router.navigate(['/admin', 'referrer']);
  }

  ngOnInit() {
    this.modelSubscription = this.referrerUrlService.data$.subscribe((data) => {
      this.modelData = data;
    });
    this.sub = this.route.params.subscribe(params => {
      this.referrerUrlService.loadReferrerUrl(params['id']);
    });
  }

}
