import { Component, OnInit, OnDestroy } from '@angular/core';
import { RapidStatsService } from '../rapid-stats.service';
import { DevicesService } from './devices.service';
import { AuthService } from '../../common';


@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-rapid-stats-devices',
  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./devices.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './devices.component.html'
})
export class DevicesComponent implements OnInit, OnDestroy {
  public selectedName1 = '';
  public selectedName2 = '';
  public arrDeviceTypes1 = [];
  public arrDeviceModels1 = [];
  public arrDeviceBrands1 = [];
  public arrResolutions1 = [];
  private deviceTypesSubscription1;
  private deviceModelsSubscription1;
  private deviceBrandsSubscription1;
  private resolutionsSubscription1;

  public arrDeviceTypes2 = [];
  public arrDeviceModels2 = [];
  public arrDeviceBrands2 = [];
  public arrResolutions2 = [];
  private deviceTypesSubscription2;
  private deviceModelsSubscription2;
  private deviceBrandsSubscription2;
  private resolutionsSubscription2;

  constructor(private rapidStatsService: RapidStatsService, private devicesService: DevicesService, public authService: AuthService) {

  }

  showSelected1() {
    return (this.arrDeviceTypes1.length > 0) ? true : false;
  }

  showSelected2() {
    return (this.arrDeviceTypes2.length > 0) ? true : false;
  }

  setRowClasses(data) {
    const classes = {
      'table-success': (data.pageType === 'gemeinsamtrauern') ? true : false
    };
    return classes;
  }

  ngOnInit() {
    this.devicesService.changeSubscribe();

    this.deviceTypesSubscription1 = this.devicesService.deviceTypes1$.subscribe((res) => {
      this.selectedName1 = this.devicesService.selectedName1;

      this.arrDeviceTypes1 = res.map(val => {
        return {
          name: val.deviceType,
          total: val.total
        };
      });
    });
    this.deviceTypesSubscription2 = this.devicesService.deviceTypes2$.subscribe((res) => {
      this.selectedName2 = this.devicesService.selectedName2;
      this.arrDeviceTypes2 = res.map(val => {
        return {
          name: val.deviceType,
          total: val.total
        };
      });
    });
    this.devicesService.loadDeviceTypes();

    this.deviceModelsSubscription1 = this.devicesService.deviceModels1$.subscribe((res) => {
      this.arrDeviceModels1 = res.map(val => {
        return {
          name: <number>(val.deviceModel.length) ? val.deviceModel : 'unbekannt',
          total: val.total
        };
      });

    });

    this.deviceModelsSubscription2 = this.devicesService.deviceModels2$.subscribe((res) => {
      this.arrDeviceModels2 = res.map(val => {
        return {
          name: <number>(val.deviceModel.length) ? val.deviceModel : 'unbekannt',
          total: val.total
        };
      });
    });
    this.devicesService.loadDeviceModels();

    this.deviceBrandsSubscription1 = this.devicesService.deviceBrands1$.subscribe((res) => {
      this.arrDeviceBrands1 = res.map(val => {
        return {
          name: val.deviceBrand,
          total: val.total
        };
      });
    });

    this.deviceBrandsSubscription2 = this.devicesService.deviceBrands2$.subscribe((res) => {
      this.arrDeviceBrands2 = res.map(val => {
        return {
          name: val.deviceBrand,
          total: val.total
        };
      });
    });

    this.devicesService.loadDeviceBrands();

    this.resolutionsSubscription1 = this.devicesService.resolutions1$.subscribe((res) => {
      this.arrResolutions1 = res.map(val => {
        return {
          name: val.resolution,
          total: val.total
        };
      });
    });

    this.resolutionsSubscription2 = this.devicesService.resolutions2$.subscribe((res) => {
      this.arrResolutions2 = res.map(val => {
        return {
          name: val.resolution,
          total: val.total
        };
      });
    });
    this.devicesService.loadResolutions();
  }

  ngOnDestroy() {
    this.deviceTypesSubscription1.unsubscribe();
    this.deviceModelsSubscription1.unsubscribe();
    this.deviceBrandsSubscription1.unsubscribe();
    this.resolutionsSubscription1.unsubscribe();
    this.deviceTypesSubscription2.unsubscribe();
    this.deviceModelsSubscription2.unsubscribe();
    this.deviceBrandsSubscription2.unsubscribe();
    this.resolutionsSubscription2.unsubscribe();
    this.devicesService.changeUnsubscribe();
  }
}
