import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rapidUrl' })
export class RapidUrlPipe implements PipeTransform {
  transform(url: string, part: string) {
    let re = /((http|https|android-app):\/\/([^\/]*))(.*)/;
    if (url.indexOf('/Community/CommunityLogin') !== -1) {
      re = /((http|https|android-app):\/\/(.*returnUrl=))(.*)/;
    }
    let m;
    if ((m = re.exec(url)) !== null) {
      if (m[4].length > 1) {
        if (part === 'url') {
          return m[1] + '...';
        } else {
          return m[4];
        }
      } else {
        if (part === 'url') {
          return '';
        } else {
          return url;
        }
      }
    }
    return url;
  }
}
