import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


import { StatsSidebarComponent } from './sidebar/stats-sidebar.component';
import { OptionsbarComponent } from './optionsbar/optionsbar.component';
import { VisitorService } from './visitor/visitor.service';
import { VisitorComponent } from './visitor/visitor.component';
import { RapidStatsComponent } from './rapid-stats.component';
import { RapidStatsService } from './rapid-stats.service';
import { EntryPagesService } from './entry-pages/entry-pages.service';
import { EntryPagesComponent } from './entry-pages/entry-pages.component';
import { DevicesService } from './devices/devices.service';
import { DevicesComponent } from './devices/devices.component';
import { PageViewsService } from './page-views/page-views.service';
import { PageViewsComponent } from './page-views/page-views.component';
import { GedenkportalService } from './gedenkportal/gedenkportal.service';
import { GedenkportalComponent } from './gedenkportal/gedenkportal.component';
import { ExitPagesService } from './exit-pages/exit-pages.service';
import { ExitPagesComponent } from './exit-pages/exit-pages.component';
import { ChartsModule } from 'ng2-charts';
import { routing } from './rapid-stats.routing';

import { NgSelectModule } from '@ng-select/ng-select';
import { DeskModule } from '../desk/desk.module';

import { MinutesAndSecondsPipe } from '../common/minutesAndSeconds.pipe';
import { RapidUrlPipe } from '../common/rapidUrl.pipe';
import { DeDatePipe } from '../common/dedate.pipe';
import { Ng2PageScrollModule } from 'ng2-page-scroll';

import { ReferrerOverviewComponent } from './referrer/referrer-overview/referrer-overview.component';
import { ReferrerSearchEnginesComponent } from './referrer/referrer-search-engines/referrer-search-engines.component';
import { ReferrerWebsitesComponent } from './referrer/referrer-websites/referrer-websites.component';
import { ReferrerService } from './referrer/referrer.service';
import { GedenkportalInternalComponent } from './gedenkportal-internal/gedenkportal-internal.component';
import { GedenkportalInternalService } from './gedenkportal-internal/gedenkportal-internal.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    NgSelectModule,
    DeskModule,
    Ng2PageScrollModule,
    routing
  ],
  declarations: [
    RapidStatsComponent,
    VisitorComponent,
    DevicesComponent,
    EntryPagesComponent,
    PageViewsComponent,
    GedenkportalComponent,

    ExitPagesComponent,
    OptionsbarComponent,
    StatsSidebarComponent,
    MinutesAndSecondsPipe,
    RapidUrlPipe,
    DeDatePipe,
    RapidUrlPipe,
    ReferrerOverviewComponent,
    ReferrerSearchEnginesComponent,
    ReferrerWebsitesComponent,
    GedenkportalInternalComponent
  ],

  providers: [
    VisitorService,
    RapidStatsService,
    EntryPagesService,
    ExitPagesService,
    PageViewsService,
    DevicesService,
    ReferrerService,
    GedenkportalService,
    GedenkportalInternalService
  ]
})
export class RapidStatsModule { }
