import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ReferrerService } from '../referrer.service';
import { RapidStatsService } from '../../rapid-stats.service';


@Component({
  selector: 'app-referrer-overview',
  templateUrl: './referrer-overview.component.html',
  styleUrls: ['./referrer-overview.component.scss']
})
export class ReferrerOverviewComponent implements OnInit, OnDestroy {


  public referrer1: any = {};
  public referrer2: any = {};
  @Input() name: string;

  public historyChartData: Array<any> = [];
  public historyChartLabels: Array<any> = [];

  public historyChartOptions: any = {
    animation: false,
    responsive: true
  };

  public historyChartColors: Array<any> = this.rapidStatsService.chartColors;
  public historyChartLegend = true;

  private referrerSubscription1;
  private referrerSubscription2;
  private historySubscription;

  constructor(private rapidStatsService: RapidStatsService, private referrerService: ReferrerService) {
  }


  showSelected1() {
    if (typeof this.referrer1.total !== 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  showSelected2() {
    if (typeof this.referrer2.total !== 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
    this.referrerService.changeSubscribe('overview');
    this.referrerSubscription1 = this.referrerService.referrer1$.subscribe((referrer) => {
      this.referrer1 = referrer;
    });

    this.referrerSubscription2 = this.referrerService.referrer2$.subscribe((referrer) => {
      this.referrer2 = referrer;
    });

    this.historySubscription = this.referrerService.history$.subscribe((history) => {
      const totalReferrer1 = { data: [], label: 'Gesamt' };
      const searchReferrer1 = { data: [], label: 'Suchmaschinen' };
      const rapidReferrer1 = { data: [], label: 'Rapid Data' };
      const directReferrer1 = { data: [], label: 'Direkte Zugriffe' };
      const websiteReferrer1 = { data: [], label: 'Webseiten' };

      const totalReferrer2 = { data: [], label: 'Gesamt 2' };
      const searchReferrer2 = { data: [], label: 'Suchmaschinen 2' };
      const rapidReferrer2 = { data: [], label: 'Rapid Data 2' };
      const directReferrer2 = { data: [], label: 'Direkte Zugriffe 2' };
      const websiteReferrer2 = { data: [], label: 'Webseiten 2' };

      const historyChartLabels: Array<any> = [];

      if (typeof history[0] !== 'undefined' && history[0].length) {
        for (const val of history[0]) {
          totalReferrer1.data.push(val.total);
          searchReferrer1.data.push(val.search);
          rapidReferrer1.data.push(val.rapid);
          directReferrer1.data.push(val.direct);
          websiteReferrer1.data.push(val.website);
          historyChartLabels.push(val.de_rangeStart + ' - ' + val.de_rangeEnd);
        }
        if (typeof history[1] !== 'undefined' && history[1].length) {
          for (const val of history[1]) {
            totalReferrer2.data.push(val.total);
            searchReferrer2.data.push(val.search);
            rapidReferrer2.data.push(val.rapid);
            directReferrer2.data.push(val.direct);
            websiteReferrer2.data.push(val.website);
          }
          this.historyChartData = [searchReferrer1, rapidReferrer1, directReferrer1, websiteReferrer1, searchReferrer2,
            rapidReferrer2, directReferrer2, websiteReferrer2];
        } else {
          this.historyChartData = [searchReferrer1, rapidReferrer1, directReferrer1, websiteReferrer1];
        }
        this.historyChartLabels = historyChartLabels;
      }
    });


    this.referrerService.loadReferrer();
    this.referrerService.loadHistory();
  }




  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.historySubscription.unsubscribe();
    this.referrerSubscription1.unsubscribe();
    this.referrerSubscription2.unsubscribe();
    this.referrerService.changeUnsubscribe();
  }

}
