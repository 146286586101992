import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../common/auth.guard';
import { DeskComponent } from './desk.component';

const deskRoutes: Routes = [
    {
        path: '',
        redirectTo: '/stats',
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },

];


export const deskRouting: ModuleWithProviders = RouterModule.forChild(deskRoutes);
