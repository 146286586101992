import { Component, OnInit, OnDestroy } from '@angular/core';
import { RapidStatsService } from '../rapid-stats.service';
import { ExitPagesService } from './exit-pages.service';
import { AuthService } from '../../common';

@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-rapid-stats-exit-pages',
  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./exit-pages.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './exit-pages.component.html'
})
export class ExitPagesComponent implements OnInit, OnDestroy {
  public selectedName1 = '';
  public exitPages1 = [];
  public selectedName2 = '';
  public exitPages2 = [];
  private exitPagesSubscription1;
  private exitPagesSubscription2;

  constructor(private rapidStatsService: RapidStatsService, private exitPagesService: ExitPagesService, private authService: AuthService) {

  }


  showSelected1() {
    return (this.exitPages1.length > 0) ? true : false;
  }

  showSelected2() {
    return (this.exitPages2.length > 0) ? true : false;
  }

  setRowClasses(data) {
    const classes = {
      'bg-rapid-hellblau': (data.pageType === 'gemeinsamtrauern') ? true : false
    };
    return classes;
  }

  ngOnInit() {
    this.exitPagesService.changeSubscribe();

    this.exitPagesSubscription1 = this.exitPagesService.exitPages1$.subscribe((res) => {
      this.selectedName1 = this.exitPagesService.selectedName1;

      this.exitPages1 = res.filter(val => (val.exit > 0) ? true : false).map(val => {
        return {
          url: val.url,
          pageType: val.pageType,
          unique: val.unique,
          exit: val.exit,
          exitRate: Math.round((100 / val.unique) * val.exit)
        };
      });
    });

    this.exitPagesSubscription2 = this.exitPagesService.exitPages2$.subscribe((res) => {
      this.selectedName2 = this.exitPagesService.selectedName2;
      this.exitPages2 = res.filter(val => (val.exit > 0) ? true : false).map(val => {
        return {
          url: val.url,
          pageType: val.pageType,
          unique: val.unique,
          exit: val.exit,
          exitRate: Math.round((100 / val.unique) * val.exit)
        };
      });
    });
    this.exitPagesService.loadExitPages();
  }

  ngOnDestroy() {
    this.exitPagesSubscription1.unsubscribe();
    this.exitPagesSubscription2.unsubscribe();
    this.exitPagesService.changeUnsubscribe();
  }
}
