import { Component } from '@angular/core';


@Component({
  styleUrls: [ './rapid-stats.component.scss' ],
  templateUrl: './rapid-stats.component.html'
})

export class RapidStatsComponent {

  constructor() {
  }

}
