import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'xeli-editbar',
  templateUrl: './editbar.component.html',
  styleUrls: ['./editbar.component.scss']
})

export class EditbarComponent {
  @Output() save = new EventEmitter<boolean>();
  @Output() remove = new EventEmitter<boolean>();
  @Output() overview = new EventEmitter<boolean>();

  @Input() heading: string;
  @Input() overviewLabel: string;

  constructor() {
  }

  onSave() {
    this.save.emit(true);
  }

  onRemove() {
    this.remove.emit(true);
  }

  onOverview() {
    this.overview.emit(true);
  }
}
