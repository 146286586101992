declare var $: any;
declare var moment: any;

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from '../../admin/user/user.service';
import { AuthService } from '../../common';
import { MorticianGroupService } from '../../admin/mortician-group/mortician-group.service';
import { RapidStatsService } from '../rapid-stats.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-rapid-stats-optionsbar',
  templateUrl: './optionsbar.component.html',
  styleUrls: ['./optionsbar.component.scss'],
})

export class OptionsbarComponent implements OnInit, AfterViewInit {
  private arrUserData: any;
  private arrMorticianGroupData: any;
  public disableInput = false;
  public items: any = [];
  private selectedUserOrGroups = [];

  constructor(private rapidStatsService: RapidStatsService,
    private userService: UserService,
    private morticianGroupService: MorticianGroupService,
    private authService: AuthService) {
  }

  public selectUserOrGroup(value: any): void {
    if (value && value.id) {
      const i = _.findIndex(this.items, { id: value.id, type: value.type });
      this.rapidStatsService.addSelected(this.items[i]);
    }
  }


  private removeUserOrGroup(value: any) {
    this.rapidStatsService.removeSelected(value);
  }



  ngOnInit() {
    this.userService.list$.subscribe((data) => {
      this.arrUserData = data.filter((user) => typeof user.piwikSiteId !== 'undefined' ? true : false)
        .map((user) => {
          return { id: user.id, text: user.firm, type: 'user' };
        });
      this.morticianGroupService.loadGroups();
    });

    this.morticianGroupService.list$.subscribe((data) => {
      this.arrMorticianGroupData = data.map((val) => {
        return { id: val.id, text: val.name, members: val.members, type: 'group' };
      });
      this.items = this.arrUserData.concat(this.arrMorticianGroupData);
    });

    this.rapidStatsService.selected$.subscribe((data) => {
      this.selectedUserOrGroups = data;
      this.disableInput = (this.selectedUserOrGroups.length <= 1) ? false : true;
    });
    this.userService.loadUsers();
  }

  ngAfterViewInit() {
    const start = this.rapidStatsService.rangeStart;
    const end = this.rapidStatsService.rangeEnd;

    (<any>$('#reportrange')).daterangepicker({
      'showWeekNumbers': true,
      'alwaysShowCalendars': true,
      'startDate': start,
      'endDate': end,
      'maxDate': moment(),
      'locale': {
        'format': 'DD.MM.YYYY',
        'applyLabel': 'Anwenden',
        'cancelLabel': 'Abbrechen'
      },
      'ranges': {
        'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'letzte 7 Tage': [moment().subtract(6, 'days'), moment()],
        'letzte 30 Tage': [moment().subtract(29, 'days'), moment()],
        'aktueller Monat': [moment().startOf('month'), moment().endOf('month')],
        'letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    });


    (<any>$('#reportrange')).on('apply.daterangepicker', (ev, picker) => {
      this.rapidStatsService.rangeStart = picker.startDate;
      this.rapidStatsService.rangeEnd = picker.endDate;
      this.rapidStatsService.refreshStats();

    });

  }

}
