import { Component, OnInit, OnDestroy } from '@angular/core';
import { RapidStatsService } from '../rapid-stats.service';
import { EntryPagesService } from './entry-pages.service';
import { AuthService } from '../../common';

@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-rapid-stats-entry-pages',
  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./entry-pages.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './entry-pages.component.html'
})
export class EntryPagesComponent implements OnInit, OnDestroy {
  public showData = false;
  public selectedName1 = '';
  public entryPages1 = [];
  public selectedName2 = '';
  public entryPages2 = [];
  private entryPagesSubscription1;
  private entryPagesSubscription2;

  constructor(private rapidStatsService: RapidStatsService,
    private entryPagesService: EntryPagesService,
    private authService: AuthService) {

  }

  setRowClasses(data) {
    const classes = {
      'bg-rapid-hellblau': (data.pageType === 'gemeinsamtrauern') ? true : false
    };
    return classes;
  }

  showSelected2() {
    if (this.entryPages2.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    this.entryPagesService.changeSubscribe();

    this.entryPagesSubscription1 = this.entryPagesService.entryPages1$.subscribe((res) => {
      this.selectedName1 = this.entryPagesService.selectedName1;
      this.showData = false;

      this.entryPages1 = res.map(val => {
        return {
          url: val.url,
          avgGenerationTime: Math.round(val.avgGenerationTime * 100) / 100,
          avgTimeSpent: Math.round(val.avgTimeSpent),
          pageType: val.pageType,
          total: val.total,
          bounce: val.bounce,
          bounceRate: Math.round((100 / val.total) * val.bounce),
          entry: val.entry
        };
      });

      if (this.entryPages1.length > 0) {
        this.showData = true;
      }
    });

    this.entryPagesSubscription2 = this.entryPagesService.entryPages2$.subscribe((res) => {
      this.selectedName2 = this.entryPagesService.selectedName2;

      this.entryPages2 = res.map(val => {
        return {
          url: val.url,
          avgGenerationTime: Math.round(val.avgGenerationTime * 100) / 100,
          avgTimeSpent: Math.round(val.avgTimeSpent),
          pageType: val.pageType,
          total: val.total,
          bounce: val.bounce,
          bounceRate: Math.round((100 / val.total) * val.bounce),
          entry: val.entry
        };
      });
    });
    this.entryPagesService.loadEntryPages();
  }

  ngOnDestroy() {
    this.entryPagesSubscription1.unsubscribe();
    this.entryPagesSubscription2.unsubscribe();
    this.entryPagesService.changeUnsubscribe();
  }
}
