import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ListbarComponent } from '../../listbar';
import { MorticianGroupService } from './mortician-group.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-mortician-group-list',
  templateUrl: './mortician-group-list.component.html',
  styleUrls: [ './mortician-group-list.component.scss' ]
})

export class MorticianGroupListComponent implements OnInit {
  public listData$;

  constructor(private router: Router, private morticianGroupService: MorticianGroupService) {
  }

  create(event) {
    this.router.navigate(['/admin', 'mortician-groups', '0']);
  }

  ngOnInit() {
    this.listData$ = this.morticianGroupService.list$;
    this.morticianGroupService.loadGroups();
  }


}
