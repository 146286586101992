import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../common/auth.guard';
import { UserEditComponent } from './user';
import { UserListComponent } from './user';
import { MorticianGroupEditComponent } from './mortician-group';
import { MorticianGroupListComponent } from './mortician-group';

// import { ReferrerListComponent } from './referrer/referrer-list/referrer-list.component';
import { ReferrerListComponent } from './referrer';
import { ReferrerEditComponent } from './referrer';

import { AdminComponent } from './admin.component';
import { DeskComponent } from '../desk/desk.component';

const adminRoutes: Routes = [
  {
    path: 'admin',
    component: DeskComponent,
    children: [
      {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuard],
        children: [
          { path: '', component: UserListComponent },
          { path: 'user', component: UserListComponent },
          { path: 'user/:id', component: UserEditComponent },
          { path: 'mortician-groups', component: MorticianGroupListComponent },
          { path: 'mortician-groups/:id', component: MorticianGroupEditComponent },
          { path: 'referrer', component: ReferrerListComponent },
          { path: 'referrer/:id', component: ReferrerEditComponent },
        ]
      }
    ]
  }
];


export const adminRouting: ModuleWithProviders = RouterModule.forChild(adminRoutes);
