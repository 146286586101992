import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RapidStatsService } from '../rapid-stats.service';

export interface StatsData {
  uniqueVisitors?: number;
  totalVisitors?: number;
  de_rangeStart?: string;
  de_rangeEnd?: string;
  err?: any;
}


@Injectable()
export class VisitorService {

  private _visits1$: Subject<StatsData>;
  private _visits2$: Subject<StatsData>;
  private _history$: Subject<StatsData[][]>;

  private _changeSubscription;

  private isLoading = true;


  private dataStore: {
    visits1: StatsData,
    visits2: StatsData,
    history: StatsData[][]
  };


  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      visits1: {},
      visits2: {},
      history: []
    };
    this._visits1$ = <Subject<StatsData>>new Subject();
    this._visits2$ = <Subject<StatsData>>new Subject();
    this._history$ = <Subject<StatsData[][]>>new Subject();
  }

  changeSubscribe() {
    this._changeSubscription = this.statsService.changed$.subscribe((val) => {
      this.isLoading = true;
      this.loadVisits();
      this.loadHistory();
    });
  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get visits1$() {
    return this._visits1$.asObservable();
  }

  get visits2$() {
    return this._visits2$.asObservable();
  }

  get history$() {
    return this._history$.asObservable();
  }

  loadVisits() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {

        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getVisits', { params: params })
          .subscribe((res: any) => {
            if (typeof this.statsService.selected[i] !== 'undefined') {
              res.selectedName = this.statsService.selected[i].text;
            }
            if (i === 0) {
              this.dataStore.visits1 = res;
              this._visits1$.next(this.dataStore.visits1);
            } else {
              this.dataStore.visits2 = res;
              this._visits2$.next(this.dataStore.visits2);
            }
          }, error => console.log('Could not load visits.'));
      } else {
        if (i === 0) {
          this.dataStore.visits1 = {};
          this._visits1$.next(this.dataStore.visits1);
        } else {
          this.dataStore.visits2 = {};
          this._visits2$.next(this.dataStore.visits2);
        }
      }
    }
    return;
  }

  loadHistory() {
    // History leeren
    this.dataStore.history = [];

    if (typeof this.statsService.selected[0] === 'undefined') {
      return;
    }

    const periodDays = Math.abs(this.statsService.rangeStart.diff(this.statsService.rangeEnd, 'days')) + 1;
    let periods = 6;
    if (periodDays === 1) {
      periods = 30;
    } else if (periodDays <= 7) {
      periods = 12;
    }

    const observables = [];

    for (let j = 0; j < periods; j++) {
      const tmpStart = this.statsService.rangeStart.clone();
      tmpStart.subtract(j * periodDays, 'days');
      const tmpEnd = this.statsService.rangeEnd.clone();
      tmpEnd.subtract(j * periodDays, 'days');
      const params: HttpParams = new HttpParams()
        .set('rangeStart', tmpStart.format('YYYY-MM-DD'))
        .set('rangeEnd', tmpEnd.format('YYYY-MM-DD'))
        .set('selectedId', this.statsService.selected[0].id)
        .set('selectedType', this.statsService.selected[0].type);

      observables.push(this.http.get('/api/stats/getVisits', { params: params }));
    }

    forkJoin(observables.reverse()).subscribe(
      (res: any[]) => {
        this.dataStore.history[0] = res;

        if (typeof this.statsService.selected[1] !== 'undefined') {
          // Datensätze für die 2. Auswahl laden
          const observables2 = [];

          for (let j = 0; j < periods; j++) {
            const tmpStart = this.statsService.rangeStart.clone();
            tmpStart.subtract(j * periodDays, 'days');
            const tmpEnd = this.statsService.rangeEnd.clone();
            tmpEnd.subtract(j * periodDays, 'days');
            const params: HttpParams = new HttpParams()
              .set('rangeStart', tmpStart.format('YYYY-MM-DD'))
              .set('rangeEnd', tmpEnd.format('YYYY-MM-DD'))
              .set('selectedId', this.statsService.selected[1].id)
              .set('selectedType', this.statsService.selected[1].type);

            observables2.push(this.http.get('/api/stats/getVisits', { params: params }));
          }

          forkJoin(observables2.reverse()).subscribe(
            (res2: any[]) => {
              this.dataStore.history[1] = res2;
              this._history$.next(this.dataStore.history);
            },
            err => console.error(err)
          );
        } else {
          this.dataStore.history[1] = [];
          this._history$.next(this.dataStore.history);
        }

        this.isLoading = false;
      },
      err => console.error(err)
    );
  }


}
