declare var $: any;
declare var moment: any;

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from '../common';
import * as _ from 'lodash';


@Injectable()
export class RapidStatsService {

  public chartColors: Array<any> = [
    { // Blau
      backgroundColor: '#2274AC',
      borderColor: '#2274AC',
      pointBackgroundColor: '#2274AC',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#2274AC'
    },
    { // Rapid weißblau
      // backgroundColor: 'rgba(255,255,255,0)',
      backgroundColor: '#e8f1f6',
      borderColor: '#e8f1f6',
      pointBackgroundColor: '#e8f1f6',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#e8f1f6'
    },
    { // fast cyan
      backgroundColor: '#4ab2f8',
      borderColor: '#4ab2f8',
      pointBackgroundColor: '#4ab2f8',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#4ab2f8'
    },
    { // hellgrün
      backgroundColor: '#1bb9a4',
      borderColor: '#1bb9a4',
      pointBackgroundColor: '#1bb9a4',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#1bb9a4'
    },
    { // grün
      backgroundColor: '#148677',
      borderColor: '#148677',
      pointBackgroundColor: '#148677',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#148677'
    },
    { // rot
      backgroundColor: '#ba706f',
      borderColor: '#ba706f',
      pointBackgroundColor: '#ba706f',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#ba706f'
    },
    { // gelb
      backgroundColor: '#f8b14a',
      borderColor: '#f8b14a',
      pointBackgroundColor: '#f8b14a',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#f8b14a'
    },
    { // senf
      backgroundColor: '#ba945c',
      borderColor: '#ba945c',
      pointBackgroundColor: '##ba945c',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#ba945c'
    },
    { // Rot
      backgroundColor: '#EA4F44',
      borderColor: '#EA4F44',
      pointBackgroundColor: '#EA4F44',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#EA4F44'
    },
    { // Orange
      backgroundColor: '#b4b4b4',
      borderColor: '#b4b4b4',
      pointBackgroundColor: '#b4b4b4',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#b4b4b4'
    }
  ];


  rangeStart = moment().subtract(6, 'days');
  rangeEnd = moment();

  // Array mit max. 2 Elementen entweder User oder mortician-group
  selected: any = [];
  private _selected$: Subject<any[]>;
  // selectedPiwikSite : string[] = [];
  // selectedWebtoolIds : string[] = [];



  // Observable string sources
  private _changed$: Subject<boolean> = new Subject<boolean>();

  get changed$() {
    return this._changed$.asObservable();
  }


  constructor(private http: HttpClient, public authService: AuthService) {
    this._selected$ = <Subject<any[]>>new Subject();

    this.authService.currentUser$.subscribe((user) => {
      if (typeof user.id !== 'undefined') {
        this.selected = [{ id: user.id, text: user.firm, type: 'user' }];
      } else {
        this.selected = [];
      }

      this._selected$.next(this.selected);
      this.refreshStats();
    });
  }

  get selected$() {
    return this._selected$.asObservable();
  }

  clearSelected() {
    this.selected = [];
    this._selected$.next(this.selected);
    this.refreshStats();
  }

  addSelected(value) {
    this.selected.push(value);
    this._selected$.next(this.selected);
    this.refreshStats();
  }

  removeSelected(value) {
    const i = _.findIndex(this.selected, { id: value.id });
    if (i > -1) {
      this.selected.splice(i, 1);
      this._selected$.next(this.selected);
      this.refreshStats();
    }
  }


  // Load currently relevant stats
  refreshStats(): void {
    this._changed$.next(true);
  }

  getPiwikSite() {
    const params: HttpParams = new HttpParams()
      .set('sort', 'name,ASC');

    return this.http.get('/api/piwik/sites', { params: params });
  }
}
