import { Component } from '@angular/core';

@Component({
  // The selector is what angular internally uses
  // for `document.querySelectorAll(selector)` in our index.html
  // where, in this case, selector is the string 'home'
  selector: 'app-admin-component',  // <home></home>

  // Our list of styles in our component. We may add more to compose many styles together
  styleUrls: ['./admin.component.scss'],
  // Every Angular template is first compiled by the browser before Angular runs it's compiler
  templateUrl: './admin.component.html'
})

export class AdminComponent {

  constructor() {
  }
}
