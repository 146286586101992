import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AdminComponent } from './admin.component';
import { MorticianGroupService } from './mortician-group/mortician-group.service';
import { MorticianGroupEditComponent } from './mortician-group/mortician-group-edit.component';
import { MorticianGroupListComponent } from './mortician-group/mortician-group-list.component';
import { UserService } from './user/user.service';
import { UserEditComponent } from './user/user-edit.component';
import { UserListComponent } from './user/user-list.component';
import { AdminSidebarComponent } from './sidebar/admin-sidebar.component';
import { adminRouting } from './admin.routing';
import { DeskModule } from '../desk/desk.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReferrerListComponent } from './referrer';
import { ReferrerEditComponent } from './referrer';
import { ReferrerUrlService } from './referrer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DeskModule,
    NgSelectModule,
    adminRouting
  ],
  declarations: [
    AdminComponent,
    MorticianGroupEditComponent,
    MorticianGroupListComponent,
    UserEditComponent,
    UserListComponent,
    AdminSidebarComponent,
    ReferrerEditComponent,
    ReferrerListComponent
  ],

  providers: [
    UserService,
    MorticianGroupService,
    ReferrerUrlService
  ]
})
export class AdminModule { }
