import { Component, OnInit } from '@angular/core';
import { ReferrerUrlService } from '../referrer-url.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referrer-list',
  templateUrl: './referrer-list.component.html',
  styleUrls: ['./referrer-list.component.scss']
})
export class ReferrerListComponent implements OnInit {

  public listData$;

  constructor(private router: Router, private referrerUrlService: ReferrerUrlService) {
  }

  create(event) {
    this.router.navigate(['/admin', 'referrer', '0']);
  }

  ngOnInit() {
    this.listData$ = this.referrerUrlService.list$;
    this.referrerUrlService.loadReferrerUrls();
  }


}
