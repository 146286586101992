import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { RapidStatsService } from '../rapid-stats.service';

export interface StatsData {
  referrerName?: string;
  referrerKeyword?: string;
  referrerUrl?: string;
  total?: number;
  search?: number;
  website?: number;
  direct?: number;
  rapid?: number;
  searchPercent?: number;
  websitePercent?: number;
  directPercent?: number;
  rapidPercent?: number;
  de_rangeStart?: string;
  de_rangeEnd?: string;
  referrerType?: string;
  err?: any;
}


@Injectable()
export class ReferrerService {

  private _referrer1$: Subject<StatsData>;
  private _referrer2$: Subject<StatsData>;
  private _history$: Subject<StatsData[][]>;

  private _searchNames1$: Subject<StatsData[]>;
  private _searchKeywords1$: Subject<StatsData[]>;

  private _searchNames2$: Subject<StatsData[]>;
  private _searchKeywords2$: Subject<StatsData[]>;

  private _websites1$: Subject<StatsData[]>;
  private _websites2$: Subject<StatsData[]>;

  private _changeSubscription;
  private isLoading = true;

  private dataStore: {
    selectedName1: '',
    selectedName2: '',
    referrer1: StatsData,
    referrer2: StatsData,
    history: StatsData[][],
    searchNames1: StatsData[],
    searchKeywords1: StatsData[],
    searchNames2: StatsData[],
    searchKeywords2: StatsData[],
    websites1: StatsData[],
    websites2: StatsData[],
  };



  constructor(private http: HttpClient, private statsService: RapidStatsService) {
    this.dataStore = {
      selectedName1: '',
      selectedName2: '',
      referrer1: {},
      referrer2: {},
      history: [],
      searchNames1: [],
      searchKeywords1: [],
      searchNames2: [],
      searchKeywords2: [],
      websites1: [],
      websites2: [],
    };

    this._referrer1$ = <Subject<StatsData>>new Subject();
    this._referrer2$ = <Subject<StatsData>>new Subject();
    this._history$ = <Subject<StatsData[][]>>new Subject();

    this._searchNames1$ = <Subject<StatsData[]>>new Subject();
    this._searchKeywords1$ = <Subject<StatsData[]>>new Subject();
    this._websites1$ = <Subject<StatsData[]>>new Subject();

    this._searchNames2$ = <Subject<StatsData[]>>new Subject();
    this._searchKeywords2$ = <Subject<StatsData[]>>new Subject();
    this._websites2$ = <Subject<StatsData[]>>new Subject();
  }

  changeSubscribe(type) {
    switch (type) {
      case 'websites':
        this._changeSubscription = this.statsService.changed$.subscribe((val) => {
          this.isLoading = true;
          this.loadWebsites();
        });
        break;
      case 'search':
        this._changeSubscription = this.statsService.changed$.subscribe((val) => {
          this.isLoading = true;
          this.loadSearchKeywords();
          this.loadSearchNames();
        });
        break;
      default:
        this._changeSubscription = this.statsService.changed$.subscribe((val) => {
          this.isLoading = true;
          this.loadReferrer();
          this.loadHistory();
        });
    }

  }

  changeUnsubscribe() {
    this._changeSubscription.unsubscribe();
  }

  get selectedName1() {
    return this.dataStore.selectedName1;
  }

  get selectedName2() {
    return this.dataStore.selectedName2;
  }

  get referrer1$() {
    return this._referrer1$.asObservable();
  }

  get referrer2$() {
    return this._referrer2$.asObservable();
  }

  get history$() {
    return this._history$.asObservable();
  }

  get searchNames1$() {
    return this._searchNames1$.asObservable();
  }

  get searchNames2$() {
    return this._searchNames2$.asObservable();
  }

  get searchKeywords1$() {
    return this._searchKeywords1$.asObservable();
  }

  get searchKeywords2$() {
    return this._searchKeywords2$.asObservable();
  }

  get websites1$() {
    return this._websites1$.asObservable();
  }

  get websites2$() {
    return this._websites2$.asObservable();
  }

  loadReferrer() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getReferrer', { params: params })
          .subscribe((res: any) => {
            if (typeof this.statsService.selected[i] !== 'undefined') {
              res.selectedName = this.statsService.selected[i].text;
            }
            if (i === 0) {
              this.dataStore.referrer1 = res;
              this._referrer1$.next(this.dataStore.referrer1);
            } else {
              this.dataStore.referrer2 = res;
              this._referrer2$.next(this.dataStore.referrer2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load referrer.'));
      } else {
        if (i === 0) {
          this.dataStore.referrer1 = {};
          this._referrer1$.next(this.dataStore.referrer1);
        } else {
          this.dataStore.referrer2 = {};
          this._referrer2$.next(this.dataStore.referrer2);
        }
      }
    }
  }

  loadHistory() {
    // History leeren
    this.dataStore.history = [];

    if (typeof this.statsService.selected[0] === 'undefined') {
      return;
    }

    const periodDays = Math.abs(this.statsService.rangeStart.diff(this.statsService.rangeEnd, 'days')) + 1;
    let periods = 6;
    if (periodDays === 1) {
      periods = 30;
    } else if (periodDays <= 7) {
      periods = 12;
    }

    const observables = [];

    for (let j = 0; j < periods; j++) {
      const tmpStart = this.statsService.rangeStart.clone();
      tmpStart.subtract(j * periodDays, 'days');
      const tmpEnd = this.statsService.rangeEnd.clone();
      tmpEnd.subtract(j * periodDays, 'days');
      const params: HttpParams = new HttpParams()
        .set('rangeStart', tmpStart.format('YYYY-MM-DD'))
        .set('rangeEnd', tmpEnd.format('YYYY-MM-DD'))
        .set('selectedId', this.statsService.selected[0].id)
        .set('selectedType', this.statsService.selected[0].type);

      observables.push(this.http.get('/api/stats/getReferrer', { params: params }));
    }

    forkJoin(observables.reverse()).subscribe(
      (res: any[]) => {

        this.dataStore.history[0] = res.map((val) => {
          return val;
        });

        if (typeof this.statsService.selected[1] !== 'undefined') {
          // Datensätze für die 2. Auswahl laden
          const observables2 = [];

          for (let j = 0; j < periods; j++) {
            const tmpStart = this.statsService.rangeStart.clone();
            tmpStart.subtract(j * periodDays, 'days');
            const tmpEnd = this.statsService.rangeEnd.clone();
            tmpEnd.subtract(j * periodDays, 'days');
            const params: HttpParams = new HttpParams()
              .set('rangeStart', tmpStart.format('YYYY-MM-DD'))
              .set('rangeEnd', tmpEnd.format('YYYY-MM-DD'))
              .set('selectedId', this.statsService.selected[1].id)
              .set('selectedType', this.statsService.selected[1].type);

            observables2.push(this.http.get('/api/stats/getReferrer', { params: params }));
          }

          forkJoin(observables2.reverse()).subscribe(
            (res2: any[]) => {

              this.dataStore.history[1] = res2.map((val) => {
                return val;
              });
              this._history$.next(this.dataStore.history);
            },
            err => console.error(err)
          );
        } else {
          this.dataStore.history[1] = [];
          this._history$.next(this.dataStore.history);
        }
        this.isLoading = false;
      },
      err => console.error(err)
    );
  }

  loadSearchKeywords() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getReferrerSearchKeywords', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.searchKeywords1 = res;
              this._searchKeywords1$.next(this.dataStore.searchKeywords1);
            } else {
              this.dataStore.searchKeywords2 = res;
              this._searchKeywords2$.next(this.dataStore.searchKeywords2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load search keywords.'));
      } else {
        if (i === 0) {
          this.dataStore.searchKeywords1 = [];
          this._searchKeywords1$.next(this.dataStore.searchKeywords1);
        } else {
          this.dataStore.searchKeywords2 = [];
          this._searchKeywords2$.next(this.dataStore.searchKeywords2);
        }
      }
    }
  }

  loadSearchNames() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getReferrerSearchNames', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.searchNames1 = res;
              this.dataStore.selectedName1 = this.statsService.selected[i].text;
              this._searchNames1$.next(this.dataStore.searchNames1);
            } else {
              this.dataStore.searchNames2 = res;
              this.dataStore.selectedName2 = this.statsService.selected[i].text;
              this._searchNames2$.next(this.dataStore.searchNames2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load search names.'));
      } else {
        if (i === 0) {
          this.dataStore.searchNames1 = [];
          this._searchNames1$.next(this.dataStore.searchNames1);
        } else {
          this.dataStore.searchNames2 = [];
          this._searchNames2$.next(this.dataStore.searchNames2);
        }
      }
    }
  }


  loadWebsites() {
    for (let i = 0; i <= 1; i++) {
      if (typeof this.statsService.selected[i] !== 'undefined') {
        const params: HttpParams = new HttpParams()
          .set('rangeStart', this.statsService.rangeStart.format('YYYY-MM-DD'))
          .set('rangeEnd', this.statsService.rangeEnd.format('YYYY-MM-DD'))
          .set('selectedId', this.statsService.selected[i].id)
          .set('selectedType', this.statsService.selected[i].type);

        const req = this.http.get('/api/stats/getReferrerWebsites', { params: params })
          .subscribe((res: any) => {
            if (i === 0) {
              this.dataStore.websites1 = res;
              this.dataStore.selectedName1 = this.statsService.selected[i].text;
              this._websites1$.next(this.dataStore.websites1);
            } else {
              this.dataStore.websites2 = res;
              this.dataStore.selectedName2 = this.statsService.selected[i].text;
              this._websites2$.next(this.dataStore.websites2);
            }
            this.isLoading = false;
          }, error => console.log('Could not load websites.'));
      } else {
        if (i === 0) {
          this.dataStore.websites1 = [];
          this._websites1$.next(this.dataStore.websites1);
        } else {
          this.dataStore.websites2 = [];
          this._websites2$.next(this.dataStore.websites2);
        }
      }
    }
  }


}
